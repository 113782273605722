import React from "react";
import "./Posts.css";
import Post from "../Post/Post";

const Posts = () => {
    const blogPosts = [
        {
        title: "Hello World! First Blog Post",
        body: `Obligatory first blog post. The
        goal of the blog will be to act more as
        a diary and a playground to test out 
        some ideas in regards software. 
        Although as of this moment it is a very basic react application, 
        I will be enhancing it further down the line with color styling along with other enhancment to make it a true webapp.
        `,
        author: "Koreanfather",
        imgUrl: "",
        }
    ];

    return (
        <div className="posts-container">
            {blogPosts.map((post, index) => (
                <Post key={index} index={index} post={post} />
            ))}
        </div>
    );
};
export default Posts;